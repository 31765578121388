<template>
  <div class="container">
    <Header :active="6" />
    <div class="banner-box">
      <el-image
        class="banner-box-img"
        src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/banner.png"
        lazy
      ></el-image>
      <div class="banner-box-text-box">
        <div class="banner-box-text-box-title">
          <div>交流研究</div>
        </div>
        <div class="banner-box-text-box-tips">Communication research</div>
        <div
          class="horizontal-line"
          :style="{ marginTop: pxToRem(21), background: 'white' }"
        ></div>
      </div>
    </div>
    <div class="section1">
      <div class="section1-header-box">
        <div class="section1-header-box">
          <div class="section1-header-box-title">新闻报道</div>
          <div class="section1-header-box-tips">
            <div class="section1-header-box-tips-text">News reports</div>
          </div>
          <div
            class="horizontal-line"
            :style="{ marginTop: pxToRem(21), marginBottom: pxToRem(40) }"
          ></div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/communication' }"
              >交流研究</el-breadcrumb-item
            >
            <el-breadcrumb-item>新闻报道</el-breadcrumb-item>
            <el-breadcrumb-item>{{
              articleActived.subTitle || articleActived.title
            }}</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="articleCont">
            <p class="title">{{ articleActived.title }}</p>
            <p class="title">{{ articleActived.subTitle }}</p>
            <p class="source">
              来源：{{ articleActived.source }} 日期：{{ articleActived.date }}
            </p>
            <div class="content" v-if="articleActived.contentDesc" v-html="articleActived.contentDesc">

            </div>
            <div
              v-else
              class="content"
              v-for="(item, ind) in articleActived.content"
              :key="ind"
            >
              <p class="desc" v-if="item.type == 'text'">{{ item.value }}</p>
              <img :src="item.value" alt="" v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "communication",
  data() {
    return {
      articleList: [
        {
          title: "西安交通大学医学科研团队最新研究显示",
          subTitle: "我国糖尿病疾病负担严重，亟需加大防控工作力度",
          source: "全球健康研究院",
          date: "2021-12-29",
          content: [
            {
              type: "text",
              value:
                "2021年12月28日，国际权威医学期刊《美国医学会杂志（JAMA）》（影响因子56.3）全文发表了我国学者关于2013-2018年中国糖尿病患病和治疗状况的研究“Prevalence and treatment of diabetes in China, 2013-2018”。该研究由中国疾病预防控制中心慢性非传染性疾病预防控制中心（简称“中国疾控中心慢病中心”）和西安交通大学全球健康研究院共同牵头，来自中国疾控中心慢病中心，西安交通大学全球健康研究院、公共卫生学院，青海大学医学部公共卫生学院等单位的合作者共同完成。第一作者为中国疾控中心慢病中心王丽敏教授、青海大学彭雯教授和慢病中心赵振平女士，通讯作者为中国疾控中心慢病中心主任吴静研究员和西安交通大学领军学者、全球健康研究院院长王友发教授。",
            },
            {
              type: "text",
              value:
                "该研究基于中国慢性病及危险因素监测项目，利用2013-2014年与2018-2019年两轮采集到的343,929名具有全国代表性的人群数据，系统分析了我国成人糖尿病患病率、知晓率、治疗率、控制率以及相关危险因素的变化趋势。该研究显示，我国成人糖尿病患病率从2013年的10.9%增加到2018年的12.4%，但2018年糖尿病知晓率仅为36.7%，治疗率为32.9%，治疗控制率为50.1%，总体仍处于低水平。此外，参考国际上公认的美国糖尿病协会（ADA）诊断标准，2013-2018期间我国成人糖尿病前期患病率也从35.7%上升到38.1%，防控形势不容乐观。",
            },
            {
              type: "text",
              value: "1. 糖尿病患病率仍在上升，糖尿病前期存在隐忧",
            },
            {
              type: "text",
              value:
                "2013年我国糖尿病标化患病率为10.9%，2018年增加到12.4%；女性的糖尿病患病率均低于男性（2013年分别为10.2%和11.7%；2018年分别为11.5%和13.3%）；糖尿病患病率随着年龄而增加：2013年18-29岁人群患病率为5.0%，70岁以上人群患病率为20.7%；2018年18-29岁人群患病率为5.0%，70岁以上人群患病率为27.3%。值得注意的是，糖尿病前期患病率也在增高，2018年达到38.1%，提示人群存在潜在风险。糖尿病和糖尿病前期总患病率已经达到50.5%。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report1_1.png",
            },
            {
              type: "text",
              value: "2. 糖尿病患者知晓率、治疗率和控制率低，需要大幅度提升",
            },
            {
              type: "text",
              value:
                "虽然我国糖尿病患者的患病知晓率和治疗率总体有所上升，但仍不够理想。2018年，我国成人糖尿病患病知晓率仅为36.7%；仅近1/3 患者(32.9%) 接受过规范治疗，其中仅有50.1%的患者血糖得到了有效控制。2013年到2018年期间农村居民糖尿病知晓率有明显提高，治疗率和治疗控制率虽有提升，但无统计学差异。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report1_2.png",
            },
            {
              type: "text",
              value: "3. 糖尿病相关危险因素尚未得到有效控制",
            },
            {
              type: "text",
              value:
                "该研究显示，在2013-2018年期间，人群中可防可控的危险因素还没有得到有效控制。我国成年人的不合理饮食、身体活动不足、吸烟和饮酒等危险因素总体有所上升。 2018年我国男性吸烟率为50.1%；水果/蔬菜摄入量不足的成人约为44.4%；五年间红肉摄入量过多的比例从32.6%增加到42.3%，而身体活动不足率也从16.0%增加到22.0%。",
            },
            {
              type: "text",
              value:
                "更为严重的是，根据我国判断肥胖的体重指数（BMI）标准，五年间肥胖患病率从14.1%上升到16.5%；中心性肥胖从31.6%增加到35.4%。由此测算，2018年有一半成年人（50.1%）处于超重（BMI≥24 kg/m2）或肥胖（BMI≥28 kg/m2）状态。肥胖和超重是糖尿病的重要危险因素。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report1_3.png",
            },
            {
              type: "text",
              value: "4. 我国与全球及其他国家的比较与预测",
            },
            {
              type: "text",
              value:
                "该研究发现，2018年我国成年人糖尿病患病率（12.4%）高于全球平均水平（2019年为8.3%）；与其他国家相比，高于同属于亚洲国家的印度（2016年6.7%），但低于美国（2018年14.6%）和韩国（2016年13.7%）。",
            },
            {
              type: "text",
              value:
                "慢性病的发生、发展受到人口老龄化、城市化、社会心理压力、生活方式、环境因素、健康素养以及健康管理质量等因素的综合影响。考虑到我国庞大的糖尿病前期人群，居高不下的人群不良生活方式率，以及还在上升的超重/肥胖态势，如果不加大糖尿病防控力度，未来我国的糖尿病患病率很有可能会进一步增加，未来由此造成的后果很有可能会更加严重。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report1_4.png",
            },
            {
              type: "text",
              value: "5. 中国现在和未来需要做什么？",
            },
            {
              type: "text",
              value:
                "此研究发现具有重要的公共卫生和临床意义，提示我国需要进一步完善和加强政府主导、多部门协调、全社会参与的慢性病防控机制和综合施策，需要加大防控力度。要实施预防为主，加强危险因素控制，增加筛查提高糖尿病患病知晓，针对糖尿病前期和高危人群进行及早干预，防治结合提高糖尿病管理效果，特别是提升农村基层卫生服务机构对慢性病管理的水平，以及加强对男性、老年人群等特殊人群的精准防控的措施，提高成人糖尿病的治疗率和控制率。",
            },
            {
              type: "text",
              value:
                "如果未来可防可控的危险因素不能得到有效控制，超重肥胖率上升不能放缓，糖尿病疾病负担仍将加重，将影响“健康中国2030行动目标”的实现。",
            },
            {
              type: "text",
              value: "参考文献",
            },
            {
              type: "text",
              value:
                "1．Wang L, Peng W，Zhao Z, Zhang M, Shi Z, Song Z, Zhang X, Li C, Huang Z, Sun X, Wang L, Zhou M, Wu J, Wang Y. Prevalence and treatment of diabetes in China, 2013-2018, JAMA 2021 Dec 28",
            },
            {
              type: "text",
              value:
                "2．Wang Y, Zhao L, Gao L, Pan A, Xue H. Health policy and public health implications of obesity in China. Lancet Diabetes Endocrinol. 2021;9(7):446-461.",
            },
            {
              type: "text",
              value:
                "3．Wang L, Zhou B, Zhao Z, Yang L, Zhang M, Jiang Y, Li Y, Zhou M, Wang L, Huang Z, Zhang X, Zhao L, Yu D, Li C, Ezzati M, Chen Z, Wu J, Ding G, Li X. Body-mass index and obesity in urban and rural China: findings from consecutive nationally representative surveys during 2004–18. Lancet 2021; 398: 53–63",
            },
            {
              type: "text",
              value:
                "4．王友发，孙明晓，杨月欣. 中国肥胖预防和控制蓝皮书. 北京: 北京大学医学出版社; 2019.",
            },
            {
              type: "text",
              value:
                "5．Wang Y, Xue H, Sun M, Zhu X, Zhao L, Yang Y. Prevention and control of obesity in China. The Lancet Global Health 2019; 7: e1166-e7.",
            },
            {
              type: "text",
              value:
                "6．Wang Y, Xue H, Esposito L, Joyner MJ, Bar-Yam Y, Huang TT. Applications of complex systems science in obesity and noncommunicable chronic disease research. Adv Nutr 2014; 5: 574-577.",
            },
            {
              type: "text",
              value:
                "7．Wang Y, Xue H, Liu S. Applications of systems science in biomedical research regarding obesity and noncommunicable chronic diseases: opportunities, promise, and challenges. Adv Nutr. 2015 Jan 15;6:88-95.",
            },
            {
              type: "text",
              value:
                "原文链接： https://jamanetwork.com/journals/jama/article-abstract/2787545",
            },
            {},
          ],
        },
        {
          title: "我国干细胞制备技术获得重要突破",
          subTitle: "",
          source: "央视新闻客户端",
          date: "2022-04-13 23:40:50",
          content: [
            {
              type: "text",
              value:
                "2022年4月13日，国际知名医学期刊《Nature Medicine》刊发了北京大学生命科学学院邓宏魁教授的最新研究结果，揭示了关于干细胞治疗糖尿病的实验进展及研究成果。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report2_1.png",
            },
            {
              type: "text",
              value:
                "4月13日晚，国际学术期刊《自然》杂志发表我国干细胞制备技术重要突破，北京大学邓宏魁教授团队，首次在国际上报道使用化学小分子改变人类体细胞，获得新一代的干细胞制备技术，未来可用于治疗糖尿病、重症肝病、恶性肿瘤等重大疾病。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report2_2.png",
            },
            {
              type: "text",
              value:
                "在北京大学生命科学学院，邓宏魁教授团队通过在人体皮肤细胞的培养液中，滴上几种化学小分子制剂，经过将近一个月的培养，人体皮肤细胞的结构和形状就会彻底发生改变，成为新型的人类多潜能干细胞，也被称为人类生命发育的“种子细胞”。",
            },
            {
              type: "text",
              value: "诱导多潜能干细胞的作用和优越性",
            },
            {
              type: "text",
              value:
                "诱导多潜能干细胞(iPSCs)。由于多潜能干细胞与胚胎干细胞高度相似，具有形成人和动物个体内所有类型细胞的多向分化潜能和不断自我更新的特性, 并且不受细胞来源、免疫排斥、伦理、宗教和法律等诸多方面的限制, 使其在细胞替代治疗、组织器官再生和移植、基因治疗、发育生物学、药理及毒理学等领域的研究中有着独特的作用和优越性。",
            },
            {
              type: "text",
              value:
                "近年科学家们已成功将小鼠和人的体细胞在体外重编程为iPSCs, 并成功诱导其分化为各种细胞类型，为疾病模型的研究奠定了基础。",
            },
            {
              type: "text",
              value:
                "邓宏魁研究组大幅改进了人多能干细胞向胰岛细胞的分化方案，重点解决了如何高效率地诱导从胰腺前体细胞向胰岛内分泌细胞命运特化的难题，从而使体外大规模制备功能成熟的胰岛细胞成为可能。",
            },
            {
              type: "text",
              value:
                "作为国际首个在非人灵长类模型上系统地评价人多能干细胞来源的胰岛细胞治疗糖尿病可行性的研究，本研究在非人灵长类动物模型上证明了人多能干细胞分化的胰岛细胞在糖尿病治疗中的安全性和有效性。",
            },
            {
              type: "text",
              value: "干细胞疗法成为替代胰岛素治疗糖尿病的新策略",
            },
            {
              type: "text",
              value:
                "糖尿病是一种胰高血糖为特征的慢性代谢疾病，当胰腺产生不了足够的胰岛素或者人体无法有效地利用所产生的胰岛素时，就会出现糖尿病。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report2_3.png",
            },
            {
              type: "text",
              value:
                "随着时间推移糖尿病会对自身的多种组织器官以及系统带来严重的损害，尤其是眼、肾、心脏、血管、神经等具有慢性损害以及功能障碍。糖尿病已经成为威胁人类生命健康最普遍的一类疾病。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report2_4.png",
            },
            {
              type: "text",
              value:
                "干细胞，尤其是间充质干细胞具有低免疫原性和多能性等特性，以用于多种疾病的治疗研究。",
            },
            {
              type: "text",
              value:
                "近年来，研究人员正在开发一种基于干细胞治疗1型糖尿病的方法。研发人员采用的干细胞是一种具有自我更新、多向分化潜能和高增殖能力的成体干细胞。它们不仅能够繁殖产生新的干细胞，而且还能分化为其他类型细胞，可修复受损的机体。这些干细胞可以在某些微环境中分化为功能性细胞，如分泌胰岛素的细胞。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report2_5.png",
            },
            {
              type: "text",
              value:
                "干细胞治疗糖尿病策略一：在体外利用人的多能干细胞分化成胰腺祖细胞，继而产生能分泌胰岛素的β样细胞。",
            },
            {
              type: "text",
              value:
                "研发人员已开发出了一些不同的策略使干细胞转化为可生产胰岛素的细胞，来替换受损的细胞。随着这些技术不断发展，研究人员希望将其用于治疗另一种疾病：2型糖尿病。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report2_6.png",
            },
            {
              type: "text",
              value:
                "干细胞治疗糖尿病策略二：将干细胞移植到人体胰岛中，在胰腺微环境的诱导下定向分化为正常的成熟胰岛样细胞并分泌胰岛素，达到治疗糖尿病的目的。",
            },
            {
              type: "text",
              value:
                "全世界有4亿多2型糖尿病患者，其中许多人需要长期频繁注射胰岛素，给患者带来很大痛苦，急需一次给药长期有效的新疗法。",
            },
            {
              type: "text",
              value: "干细胞治疗糖尿病的优势",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report2_7.png",
            },
            {
              type: "text",
              value: "干细胞治疗糖尿病的优势在于：",
            },
            {
              type: "text",
              value:
                "(1)干细胞具有多向分化潜能，干细胞通过诱导能够分化成为胰岛β细胞;(2)干细胞具有强大的旁分泌作用,包括干细胞与胰岛共移植增强胰岛存活，(3)干细胞的免疫抑制作用，抑制T细胞增殖，降低炎症反应；(4)干细胞分泌的细胞因子，能够修复受损细胞，促进细胞增殖分化，具有抗氧化能力，保护内源性β 细胞，从而改善了胰岛 β细胞的功能。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report2_8.png",
            },
            {
              type: "text",
              value: "干细胞治疗糖尿病临床案例",
            },
            {
              type: "text",
              value:
                "国外也开展了利用干细胞全分化的胰岛细胞治疗1型糖尿病的人体临床试验，并获得了积极的结果 2021年10月18日，美国Vertex医药公司宣布，其干细胞的全分化胰岛细胞替代疗法在1/2期临床试验的首例1型糖尿病患者中获得积极数据：患者在接受单剂治疗90天后体内胰岛素恢复生产，并且每天胰岛素使用量减少91%。据悉，这是首次试验数据证实这一细胞疗法可显著恢复1型糖尿病患者的胰岛细胞功能。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report2_9.png",
            },
            {
              type: "text",
              value: "临床研究：干细胞治疗II型糖尿病，总有效率68%",
            },
            {
              type: "text",
              value:
                "该项研究纳入了上海长海医院内分泌科2018年5-8月收治的24例II型糖尿病患者，治疗期为6周。在此期间患者接受3次人脱落乳牙间充质干细胞（SHED-MSCs）静脉移植。入组时进行第一次移植，第二次和第三次移植分别在第一次输注后 1 周和 4 周。每次干细胞输注的剂量计算为1×106个/kg。第三次干细胞移植后 1、2、3、6、9 和 12 个月进行随访。共有22名患者完成了该项研究。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report2_10.png",
            },
            {
              type: "text",
              value:
                "结果显示，胰岛素日剂量在治疗期内显着降低，治疗期结束时较基线降低35.34%。在随访期间，胰岛素的日剂量进一步减少。与基线水平相比，治疗期结束后3、6、9个月胰岛素日剂量分别下降51.18%、43.39%和39.39%。之后，剂量相对稳定，没有明显减少。治疗期末总有效率为86.36%（19/22）。两名患者在治疗期间停止注射胰岛素。治疗期结束后6个月总有效率为81.82%（18/22）。另一名患者在随访期间停止注射胰岛素。到研究结束时，总有效率为 68.18%（15/22），其中3名患者已停止注射胰岛素（图B）。在其他4名患者中，胰岛素注射频率也比基线时有所降低。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report2_11.png",
            },
            {
              type: "text",
              value:
                "通过相关性分析发现，干细胞移植前血糖水平与疗效相关；也就是说，HbA1c <8.5% 的入组患者在治疗后显着降低了每日胰岛素剂量。患者治疗前胰岛功能状态与治疗后胰岛功能恢复程度密切相关，FCP>1.7 ng/mL、P2hCP>3 ng/mL的患者治疗后胰岛功能恢复较好（图 5B,C）。此外，干细胞治疗前 TC <5 mmol/L 或 TG ≤1.5 mmol/L 或 LDL-C < 3.2 mmol/L 的患者每日胰岛素剂量显着降低，这表明血脂水平也与干细胞治疗效果有关。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report2_12.png",
            },
            {
              type: "text",
              value:
                "此外该项研究对干细胞治疗的安全性也进行了观察。低血糖是研究期间最常见的不良事件。在治疗期间，所有 24名患者的低血糖频率均有所增加，并达到每名患者每周 0.51次的峰值。在随访期间，所有22名患者的低血糖频率均显着降低。",
            },
            {
              type: "text",
              value:
                "SHED-MSCs移植后6个月，每名患者平均每周低血糖发生频率约为0.09次，与治疗前水平相似（图 A,B）。除低血糖外，治疗期间其他常见不良反应为一过性发热（11.11%）、乏力（4.17%）、皮疹（1.39%）。所有这些不良反应均发生在SHED-MSCs移植后 24 小时内，经适当标准治疗后症状缓解。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report2_13.png",
            },
            {
              type: "text",
              value:
                "结论：上述研究结果表明，间充质干细胞注射是一种安全有效的改善2型糖尿病患者糖代谢和胰岛功能的疗法。血脂水平和基线胰岛功能可能是影响2型糖尿病患者患者间充质干细胞移植治疗结果的关键因素。",
            },
            {
              type: "text",
              value: "未来展望",
            },
            {
              type: "text",
              value:
                "2019年，美国《时代》周刊将干细胞治疗糖尿病纳入改变未来十大医疗的12大创新发明列表中。干细胞治疗糖尿病的临床应用时代已经来临。相信在不久的未来，随着研究的不断深入和临床技术的不断成熟，糖尿病患者可以完全摆脱严格控制饮食，餐前饭后关注血糖，担忧并发症危及生命的疾病困扰，获得更高质量和更加健康的生活方式",
            },
            {
              type: "text",
              value: "文中图片均已获版权方授权",
            },
          ],
        },
        {
          title: "FDA谨慎对待Bristol阿斯利康新糖尿病治疗药物",
          source: "全球健康研究院",
          date: "2013-12-25",
          content: [
            {
              type: "text",
              value:
                "伦敦-美国食品和药品管理局工作人员周二表示，他们对阿斯利康和百时美施贵宝的新型糖尿病治疗药物dapagliflozin的风险与利益表示担忧。 ",
            },
            {
              type: "text",
              value:
                "在咨询委员会讨论之前该机构在周四公布的文件中，工作人员表示最新的证据综合考虑了药物在心脏中的益处和在膀胱癌或肝脏中的损害风险。根据这些已更新的数据分析，FDA表示对于使用dapagliflozin带来的心血管益处是否能够超过已观察到的该药物在恶性肿瘤中的作用或潜在肝毒性风险，他们表示十分怀疑。",
            },
            {
              type: "text",
              value:
                "Dapagliflozin，是一种治疗2型糖尿病的新药，它可以是更多的糖排出体外，它在欧洲已经获得批准，并以Forxiga为品牌名称销售。但在2012年1月被美国监管机构拒绝，原因为对癌症和潜在肝损伤的担忧，这也促使了最近的审批。",
            },
            {
              type: "text",
              value:
                "如果这次获得批准，它将会是继强生公司在3月推出的Invokana或称canagliflozin之后的第二个到美国市场的SGLT2抑制剂药物。根据Thomson Reuters Pharma分析师预测，2019年dapagliflozin的全球销售额为8.06亿美元。 ",
            },
          ],
        },
        {
          title: "一种蛋白质与糖尿病相关",
          source: "人民网",
          date: "2014-02-21",
          contentDesc: `<p class="desc">日本京都府立医科大学近日发表的最新研究结果显示，一种名为p53的蛋白质与糖尿病相关，抑制这种蛋白质的功能可以改善实验鼠胰岛素分泌和糖耐受水平。研究人员称，这一发现有助于开发治疗糖尿病的新药。</p>
          <p class="desc">胰岛β细胞是胰岛细胞的一种，能分泌胰岛素来调节人体血糖水平，这种细胞的功能受损能够引发糖尿病。先前研究发现，糖尿病患者胰岛β细胞中的线粒体功能异常，是导致胰岛β细胞功能异常的重要原因之一。而p53蛋白是一种肿瘤抑制蛋白，被认为在调节线粒体功能上发挥着重要作用。</p>
          <p class="desc">日本研究人员在最新一期美国《国家科学院学报》网络版上报告说，对患有糖尿病的实验鼠研究发现，p53蛋白能与一种名为Parkin的蛋白质结合，导致胰岛β细胞中负责细胞能量代谢的线粒体无法更新、功能下降，使胰岛β细胞失去分泌胰岛素所需的能量。研究人员随后抑制实验鼠体内p53蛋白与Parkin蛋白的结合，结果实验鼠的胰岛素及糖耐受水平都得到了改善。</p>
          <p class="desc">研究人员认为，通过抑制p53蛋白的作用来改善胰岛β细胞的功能，有望成为预防和治疗糖尿病的一种新策略。</p>`
        },
        {
          title:
            "1 型糖尿病同种异体脐带间充质基质细胞的重复移植：一项开放平行对照临床研究",
          source: "荆璐沉珊梅清玲王斌_李丽荣,张伟,曲朵朵,颜碧&朱大龙",
          date: "2021年6月10日",
          contentDesc: `<p class="desc">背景</p>
          <p class="desc">1 型糖尿病 (T1D) 中 β 细胞功能的保存或恢复仍然是一个有吸引力且具有挑战性的治疗目标。间充质基质细胞 (MSCs) 是具有高免疫调节能力的多能细胞，已成为许多免疫疾病的有前途的细胞疗法。本研究的目的是检查一次重复移植同种异体 MSCs 在 T1D 个体中的有效性和安全性。</p>
          <p class="desc">方法</p>
          <p class="desc">这是一项非随机、开放标签、平行武装的前瞻性研究。从健康供体的脐带 (UC) 中分离出 MSC。招募了 53 名参与者，包括 33 名成人发病（≥ 18 岁）和 20 名青少年发病的 T1D。27 名受试者（MSC 治疗组）接受了同种异体 UC-MSC 的初始全身输注，然后在 3 个月时重复疗程，而对照组（n = 26）仅接受基于强化胰岛素治疗的标准护理。本研究报告了 1 年随访的数据。主要终点是临床缓解，定义为空腹和/或餐后 C 肽水平较基线增加 10%。次要终点包括副作用、血清 HbA1c 水平、空腹和餐后 C 肽的变化以及每日胰岛素剂量。</p>
          <p class="desc">结果</p>
          <p class="desc">随访1年，MSC治疗组40.7%的受试者达到主要终点，明显高于对照组。MSC 治疗组中的 3 名受试者，与对照组中没有人相比，实现了胰岛素独立并维持无胰岛素 3 至 12 个月。在成人发病的 T1D 中，MSC 治疗组的餐后 C 肽变化百分比显着高于对照组。然而，青少年发病的 T1D 组之间空腹或餐后 C 肽的变化没有显着差异。多变量逻辑回归分析表明，较低的空腹 C 肽和较高剂量的 UC-MSC 与移植后临床缓解的实现相关。没有观察到严重的副作用。</p>
          <p class="desc">结论</p>
          <p class="desc">一次重复静脉注射同种异体 UC-MSCs 对新近发病的 T1D 患者是安全的，并且与单独的标准治疗相比，在诊断后的第一年内可能会更好地保存胰岛 β 细胞。</p>`
        },
        {
          title: "澳研究发现肝脏分泌的一种蛋白质可降血糖",
          source: "新华社",
          date: "2018年09月06日",
          contentDesc: `<p class="desc">新华社悉尼9月6日电（记者陈宇）澳大利亚等国研究人员发现，由肝脏自然分泌的一种蛋白质可以降低患糖尿病实验鼠的血糖水平，希望未来能够在此基础上，开发出治疗2型糖尿病的新药。相关研究成果已发布在新一期美国《科学·转化医学》杂志上。</p>
          <p class="desc">参与研究的澳大利亚墨尔本大学博士玛格达莱妮·蒙哥马利表示，研究人员发现当实验鼠体内血糖水平过高时，肝脏会分泌一种叫作分泌性模块化钙结合蛋白1的蛋白质，由此猜想这种蛋白质可能与控制血糖水平有关。动物实验表明，向患糖尿病的实验鼠注射经过加工的这种蛋白质后，实验鼠不仅血糖水平降低了，脂肪肝也得到了控制，血胆固醇水平也降低了。</p>
          <p class="desc">研究人员表示，现有的2型糖尿病治疗药物虽然有效，但均存在耐受性和副作用等问题，他们希望基于这种蛋白质能开发出比现有药物更有效、药效更持久的新药物。</p>
          <p class="desc">研究小组下一步希望和制药公司合作，基于这种蛋白质开发出一周只需注射一次的新药物，并尽快开始临床试验。</p>`
        },
        {
          title: "糖尿病前期以及新发2型糖尿病在一年以内的，加强管理，一半以上可以逆转",
          source: "央视新闻频道",
          date: "2019年03月15日",
          content: [
            {
              type: "text",
              value:
                "6月20日，央视新闻频道在微博、快手等平台播放了一个短视频，在视频中解放军总医院内分泌科主任母义明教授说：“糖尿病是可以逆转的”。他说：“在我国成年人当中，大约有1.3亿左右的糖尿病人，而处于糖尿病前期的人数，至少有3个亿。许多人患糖尿病之后精神压力很大，担心并发症的同时，认为再也不能逆转。事实真的如此吗？国内外大量的研究证明，糖尿病前期以及新发2型糖尿病在一年以内的人群，只要我们加强管理，一半以上的人可以逆转。具体办法包括合理的饮食控制，每天至少30分钟的中等强度运动，这种运动包括快走、跑步、游泳等等，保持正常范围的体重，保证每天7-8个小时的睡眠，保持正常的血压和血脂、避免过度紧张和过大的精神压力等等。如果这些措施依然不能控制血糖，短期使用降糖药物治疗，也有助于逆转高血糖。......如果高血糖已经逆转，依然要保持健康良好的生活习惯，这样才能达到长期逆转的目的。”",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report7_1.png",
            },
            {
              type: "text",
              value:
                "今年5月15日，《2型糖尿病逆转中国专家共识》定稿会在2021年北大糖尿病论坛期间同步召开，参与《共识》定稿会的专家包括北京大学人民医院内分泌科主任、北京大学糖尿病中心主任、前国际糖尿病联盟副主席纪立农教授等来自全国的23位内分泌及营养专家。 纪立农教授提出：本次《共识》的出台将成为具有历史意义的事件，糖尿病逆转应该被旗帜鲜明地提出，不必犹豫。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report7_2.png",
            },
            {
              type: "text",
              value:
                "早在2015年，国家中医药管理局亚健康干预技术实验室刘东波教授团队率先提出糖尿病逆转理念，并于2016年开始实施CMNT逆转糖尿病干预计划。经过6000多例逆转干预案例后，2021年4月其具体干预方法公开发表于trail杂志。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report7_3.png",
            },
            {
              type: "text",
              value:
                "种种迹象表明，医院、医生、糖友的观念都在改变，糖尿病能够逆转的观念正在深入人心。",
            },
            {
              type: "text",
              value:
                "在央视新闻频道的短视频中，母义明教授提到了三个重要的论点：",
            },
            {
              type: "text",
              value: "1、糖尿病可以逆转。",
            },
            {
              type: "text",
              value:
                "2、逆转糖尿病要趁早。母教授特别强调了糖尿病前期和一年以内的新发2型糖尿病更容易逆转。",
            },
            {
              type: "text",
              value:
                "3、一半以上的人可以逆转。这说明糖尿病产生的原因过于复杂，没有人能保证百分百的逆转率，包括业内顶极专家。",
            },
            {
              type: "text",
              value:
                "国家中医药管理局亚健康干预技术实验室12年的糖尿病逆转研究亦表明：肥胖型糖尿病人、糖尿病前期以及一年以内的新发2型糖尿病最容易逆转，数年来已累计大量的成功案例；一年以上2型糖尿病也有机会逆转，只是逆转所需的时间更长。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report7_4.png",
            },
            {
              type: "text",
              value:
                "日前，国家中医药管理局亚健康干预技术实验室举办的糖病逆转干预营正在如火如荼进行中。本期逆糖营于6月18日开营，来自全国各地的多名2型糖友会聚一堂，运用CMNT中药营养干预，进行逆糖大作战。",
            },
            {
              type: "text",
              value:
                "开营以来，短短3天，逆糖效果已初步显现：餐后血糖值全部下降，其中有一半的糖友餐后两小时血糖值下降超过30%，其中有一位程女士餐后血糖值从19.1下降到8.6，降幅达55%；还有一位陈先生，餐前餐后血糖值双双下降超过30%。",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report7_5.png",
            },
            {
              type: "image",
              value: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report7_6.png",
            },
            {
              type: "text",
              value:
                "正如母义明教授所说，国内外大量的研究和实践已证明，糖尿病是可以逆转的。一位位专家教授站出来呼吁，一篇篇的科学论文在国内外发表，一个个鲜活的逆转糖友就站在你的面前，为糖尿病所苦的您，何不趁着还没发生并发症的、趁着尚在易逆转的时刻，赶紧去逆转糖尿病呢？",
            },
          ],
        },
        {
          title: "人工智能（AI）正在引领一场新的科学革命",
          source: "36氪神译局",
          date: "2021年08月09日",
          contentDesc: `<p class="desc">编者按：因为声称一个人工智能具备了“人格”，谷歌的一名研究人员被公司强制带薪休假，人工智能又火了一把。事实上，媒体的笔触和大众的眼球似乎总是放在聊天、画画这些更容易理解的事情上，但人工智能在科学领域所取得的进展尽管不那么哗众取宠，却对人类进步产生了实实在在的影响，甚至可以说，AI正在引领一场新的科学革命。本文是对人工智能在科学领域发挥的作用所进行的综述，文章来自编译。</p>
            <p class="desc" style="font-weight: bold;">划重点：</p>
            <p class="desc">人工智能推动了科学新发现</p>
            <p class="desc">人工智能改变了科学研究的方式</p>
            <p class="desc">人工智能可以快速阅读科学文献</p>
            <p class="desc">人工智能可以解读海量科研数据</p>
            <p class="desc">人工智能升级了实验室的关键仪器</p>
            <p class="desc">人工智能可以模拟复杂系统</p>
            <p class="desc">人工智能是人类在科学探索道路上的合作伙伴</p>
            <p class="desc">自1950年代发现DNA以来，生物学家就一直试图将遗传密码的长度与一系列细胞组成的过程联系起来——比方说，其中就包括特定抗体的mRNA转录，这是现在很出名的mRNA疫苗的关键。尽管自发现DNA以来，人类在测序和理解基因组方面取得了进展，但还缺失了一个重要环节。生物学家现在缺乏的是一种办法，一种只用DNA或RNA源代码来准确有效地预测未知蛋白质3D形状的方法。对生物学而言，结构决定了功能。蛋白质在细胞当中的作用取决于它的形状。中空的圆柱体是很好的膜受体，而U形酶能在其峡湾状的空腔内催化化学反应。对于理解人类疾病来说，能够预测甚至设计蛋白质将是一次飞跃，而且能为一系列疾病开辟新的治疗方法。</p>
            <p class="desc">但是70多年来，科学家们一直坚持慢速的做法，这些方法会导致计算机算力紧张，而且在很大程度上得靠自己的猜测来梳理蛋白质结构。尽管生物学家已经知晓构成每种蛋白质的各种氨基酸的DNA代码段是什么，但他们仍缺乏一个可重复的、可推广的公式来解决这个所谓的“蛋白质折叠问题”。他们需要系统地了解这件事：任何一串氨基酸，一旦连接起来之后，会如何折叠成三维的形状？解答这个问题，就可以解开浩瀚的蛋白质世界之谜。</p>
            <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report8_1.png" alt="">
            <p class="desc" style="text-align: center;font-style: italic;">* 蛋白质的折叠。资料来源：DeepMind</p>
            <p class="desc">2020 年，谷歌的人工智能团队DeepMind宣布，自己的AlphaFold算法已经解决了蛋白质折叠问题。一开始，这一惊人突破令大多数人感到兴奋，科学家们已经做好准备，随时愿意测试新工具，并对其中一些乐此不疲。毕竟，这不是几年前那个开发出AlphaGo、击败了围棋世界冠军的公司吗？围棋可是比国际象棋复杂得多的，但即使困难如此，与蛋白质折叠问题相比，掌握围棋的难度也微不足道。但是AlphaFold通过横扫对手证明了它的科学精神。各支生物学家团队每年都要参加一项竞赛，仅根据蛋白质的遗传密码来猜测蛋白质的结构。AlphaFold算法也报名参赛了，而且表现远远超过了人类竞争对手，其预测的最终形状误差只有一埃（一个原子的宽度）。不久之后，AlphaFold正确预测出了SARS-CoV-2的“刺突”蛋白（这种病毒的膜受体正是疫苗靶向攻击的目标）的形状，从而通过了现实世界的第一次测试。</p>
            <p class="desc">AlphaFold的成功很快就变得无法忽视，科学家们开始在实验室试用这一算法。到了2021年，《科学》杂志更把AlphaFold的开源版本评为“年度最佳方法”（Method of the Year）。生物化学家，《科学》杂志主编霍顿·索普（H. Holden Thorp）在一篇社论中写道：“就科学成就和对未来研究的赋能而言，蛋白质折叠方面的突破是有史以来最伟大的突破之一。”时至今日，AlphaFold的预测已经非常准确，准确到经过70多年的探寻之后，蛋白质折叠问题被认为已得到解决。虽然蛋白质折叠问题可能是迄今为止人工智能在科学领域取得的最引人注目的成就，但人工智能正悄然在许多科学领域取得新的发现。</p>
            <p class="desc">通过加速发现过程，并为科学家提供新的调查工具，人工智能也在改变做科学研究的方式。这种技术升级了显微镜和基因组测序仪等研究的支柱，为仪器增加了新的技术能力，让它们变得更加强大。人工智能赋能的药物设计与重力波探测器为科学家提供了探索和控制自然界的新工具。在实验室以外的地方，人工智能还可以部署先进的仿真能力与推理系统，开发出真实世界的模型，并应用这些模型去对假设进行测试验证。由于人工智能对各种科学方法都产生了广泛影响，凭借着突破性的发现、新技术、增强工具以及加快科学进程速度和准确性的自动化方法，人工智能正在引发一场科学革命。</p>
            <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report8_2.png" alt="">
            <p class="desc" style="text-align: center;font-style: italic;">* 资料来源：对宇宙进行的超分辨率模拟，由人工智能提供辅助</p>
            <p class="desc">除了蛋白质折叠问题以外，人工智能还凭借着在众多领域取得的发现证明了自身的科学价值，比如宇宙学、化学、半导体设计以及材料科学等。比方说，DeepMind的团队又开发了一种计算分子电子密度的算法，其表现已经超越了科学家已经采用了60年的一种快捷手段。了解特定分子的电子密度对于理解材料的物理和化学性质很有帮助。但由于电子受到量子力学的约束，计算特定电子密度的方程非常复杂，会很快变成计算的噩梦。所以，科学家们转而采用材料电子的平均密度作为指导，从容避开了难度很大的量子计算。但DeepMind的算法则是迎难而上，直接解决了量子方面的问题，而且已被证明比那种快捷方式更加准确。与解决蛋白质折叠问题类似，人工智能胜过了科学家沿用几十年的方法，还解锁了准确预测物理和化学性质的新方法。</p>
            <p class="desc">人工智能对科学的影响并不仅限于新发现，在这四个关键方面也很有影响。首先，人工智能可以快速阅读科学文献，从而学会科学的基本规则、事实和方程式，并帮助科学家管理淹没各个领域的大量论文和数据。比方说，光是2020年这一年，就有100000到180000篇COVID-19方面的科学期刊发表。虽说研究人员将重点放在像全球疫情的持续流行这样的紧迫事情上有它的道理，但COVID-19的相关论文仅占最大的生物医学数据库文章总量的4%-6%左右。产生出来的论文和数据太多了，已经远远超过了任何一位科学家的阅读能力，令研究人员没法跟上所在领域的创新步伐。</p>
            <p class="desc">而这就是人工智能的用武之地。比方说，在药物化学领域，Insilico公司推出了一款完全由人工智能设计的药物，旨在治疗一种叫做特发性肺纤维化(IPF)的疾病。现在这款药物已经进入到I期临床试验阶段。Insilico的人工智能是怎么设计药物的？他们的算法会阅读医学文献，然后选定疾病目标，找出可以精确定位的潜在的蛋白质、细胞或病原体。一旦选定目标，算法就可以设计出一种治疗方法来治疗疾病。Insilico为此开发了一个用于药物发现的端到端人工智能平台，此平台可以自动获取到该领域的最新结果和数据，这样科学家们既可以及时了解情况，又不会被海量数据压倒。</p>
            <p class="desc">其次，随着仪器变得越来越精确，随着对自然的发掘越来越深入，科学家们也要面对海量的数据。在这方面，人工智能也可以提供帮助。美国阿尔贡国家实验室（Argonne National Laboratory）的一组科学家已经开发出一种算法，这种算法可以理解时空连续体结构里面的引力波——爱因斯坦预测了这种涟漪的存在，但直到2015年引力波才被发现。在短短7分钟的时间之内，该算法就处理了一个月的数据，实现对引力波加速、可扩展和可重复的检测。</p>
            <p class="desc">甚至更好的是，这种算法还可以在标准图形处理单元（GPU）上面运行，这样一来研究人员就不需要用专门的设备来收集引力波数据并对其做出解释。阿尔贡数据科学与学习部（DSL）的主管Ian Foster表示：“这个项目让我感到兴奋的是，它展示了如何通过适当的工具，将人工智能方法自然地集成到科学家的工作流程当中——让他们能够更快更好地完成他们的工作——是增强，而不是取代人类智能。”有了人工智能，曾经的数据洪水猛兽现在变成了可驾驭的能加速科学步伐的信息流。</p>
            <p class="desc">第三，人工智能一直在对成为任何实验室支柱的那些仪器进行悄然升级：显微镜和DNA测序仪。在美国阿尔贡国家实验室，研究人员找到了一种方法来提高电子显微镜检索到相关样品信息的能力，同时还提高了仪器的分辨率和灵敏度。电子显微镜跟许多人在高中或大学生物课上见到的显微镜不一样，因为它们构建图像靠的不是可见光。相反，顾名思义，它们用的是电子，所以电子显微镜拍出来的图像要比其他显微镜拍出来的分辨率更高，图像更精细。美国阿尔贡国家实验室的研究人员还设计了一种方法，在电子显微镜上用人工智能记录相位数据，这些数据可传递关于样品物理和化学特性的关键信息，从而提高了仪器的能力。</p>
            <p class="desc">多少有点类似，人工智能带来的另一个升级是对所谓的光场显微镜的升级。这种仪器可以拍摄高清的3D运动图像。在过去，科学家们往往需要几天的时间来重构影像，但有了人工智能之后，处理这种高分辨率的动态数据所需要的时间就缩短到了几秒钟，而且分辨率或细节也不会丢失。作为基因组时代的主力军，DNA测序仪也受益于人工智能的一臂之力。今年早些时候，一个科学家团队通过利用人工智能将DNA测序所需的时间减少了一半，而且有望很快再次减半。简而言之，人工智能正在对甚至是最基本的科学工具进行升级。</p>
            <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report8_3.png" alt="">
            <p class="desc" style="text-align: center;font-style: italic;">* 资料来源：《科学》</p>
            <p class="desc">最后，人工智能在实验室中真正大放异彩的地方是模拟复杂系统。在人工智能的帮助下，模拟复杂系统已经成为基础科学研究越来越常见的工具。去年，研究人员通过在物理学、天文学、地质学以及气候科学等十个科学领域建立其突破性的模拟，展示了人工智能的多学科能力。所有这10个仿真器都是由同一个叫做 DENSE 的深度神经网络训练出来的，与其他方法相比，其仿真速度提高了10亿倍之多，而且准度还能保持一样。关键是，仿真器可以用来解决“反问题”，也就是研究人员已经知道了结果，但是想找出是哪些变量会导致这样的输出。人工智能很擅长这种计算，而且可以很容易就能找出怎么走才能得出特定答案。</p>
            <p class="desc">模拟用处不小，但研究人员还希望确保模型在现实世界当中也一样有效。谷歌和三星这两家领先的科技公司最近开始寻求用人工智能来规划部分芯片的布局的方法。谷歌得出的结论是，人工智能设计的芯片“在所有关键指标上都优于或可与人类生产的芯片相媲美，其中就包括功耗、性能以及芯片面积等。”而且该公司还更进一步，用人工智能设计了下一代的人工智能加速器（TPU，跟标准CPU或GPU不一样，这是为人工智能定制的芯片）。同样，三星依靠人工智能芯片设计软件来制造了Exynos，这是一种用于其可穿戴产品和汽车的芯片。凭借着高保真的模拟，人工智能为科学家们提供了一种强大的工具，彻底改变了他们对自然世界进行建模和实验的方式。</p>
            <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report8_4.png" alt="">
            <p class="desc" style="text-align: center;font-style: italic;">* 资料来源：人工智能促进了对COVID-19患者的快速诊断</p>
            <p class="desc">要想考验人工智能的建模能力，在现实世界当中没有比 COVID-19 疫情更好的案例了。先是由蛋白质折叠算法 AlphaFold 正确地预测出突出的“尖峰”蛋白质，展示出人工智能在未来的疫情出现时如何可以加速疫苗或疗法的研发。</p>
            <p class="desc">但也许下面这个例子更加令人印象深刻。2020年夏天，日本科学家用全球最强大的超级计算机Fugaku模拟了COVID-19在空气中的传播。在深度神经网络以及数千个GPU的支持下，Fugaku为全球提供了病毒是通过空气传播的决定性证据，并说服WHO相应地修改了控制COVID-19的指南（比方说，口罩、通风以及室内相对室外活动的风险）。在现实世界里，人工智能通过在危机期间为全球战略提供信息来证明了自身的价值。</p>
            <p class="desc">除了做出新发现并为科学的武器库添加新工具以外，人工智能还能发现数据当中存在的模式，做出可以测试的预测，并利用预测将新证据纳入到模型之中，这个过程与科学方法十分相似。哲学家卡尔·波普尔（Karl Popper）曾经普及了这样一种观点，即科学是通过摒弃可证伪的假设（可以通过实验检验，并被证明是错误的预测）而取得进步的，而且这种通过理论和实验排除的过程是科学方法的标志。</p>
            <p class="desc">正如人工智能最近取得的一些突破所表明的那样，这项技术还生成了可通过实验测试的假设，并通过排除的过程给出严格且可证伪的答案。通过逼近科学过程，并在预测与实验反馈之间反复迭代，直到更好地掌握了量子计算，DeepMind的电子密度模型最终击败了研究人员。至于蛋白质折叠问题，人工智能的办法是用成千上万个实验确定的蛋白质来测试自己的模型，不断改进自己的猜测，对于更接近解决方案的分支，赋予更大的权重，通过这样来对神经网络进行修改。在研究人员开发出AlphaFold的开源版本之后，其他科学家已经利用这一模型来解开了新的谜题，比如RNA结构是如何折叠的，以及蛋白质是如何结合在一起的。</p>
            <p class="desc">总体而言，理解了蛋白质结合为强大的新药创新打开了大门，因为细胞的很多反应都是蛋白质协同作用的结果。这两项进步均开启了治疗设计的新时代，让人类得以利用端到端的人工智能渠道针对疾病设计精准疗法。比方说，华为人工智能实验室的一个研究团队就利用了这种模型的一个版本，自动生成针对感染目标的抗体。从药物设计到蛋白质结合，人工智能建模、分析以及控制自然的能力只会有增无减。</p>
            <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report8_5.png" alt="">
            <p class="desc">DeepMind的最新突破是应用人工智能来处理核聚变反应的控制和维持问题。人工智能成功地控制了氢与氦融合的反应，正是这种反应，为宇宙的每一颗恒星在创纪录的时间长度内提供了动力。而且人工智能还发现了稳定保持等离子体的新形态。这一实验是朝着开发可行的聚变能迈出的重要一步，而聚变能可以提供足够的可再生能源来为全世界供电。这个案例突出展示了人工智能在科学领域最有希望的用途：它看到了我们看不到的模式，并从不同但互补的角度分析我们的环境。通过与研究人员的合作，并以科学方法为基础，人工智能能够利用支撑科学过程的迭代理论和实验来解决同样的探索性问题。</p>
            <p class="desc">对科学最好的描述是探索未知。在这个旅程之中，人工智能是我们的合作伙伴，它以不同于人类的方式去感知自然世界及其未被探索的部分，从而开辟出理解和利用我们这个世界的力量的新方式。正如索尼研究总监 Hiroaki Kitano所说那样，科学发现属于“搜索”问题，它的基础是假设、实验和数据的自我纠正系统，而这些东西人工智能都可以模仿。通过自动化与算法，人工智能可以将人类无法建立连接的原因与相关性联系在一起。但人工智能不仅仅是科学家手中的强大工具，不仅仅是搜索路上的合作伙伴。人工智能还改变了科学过程，把人们用它可以完成的事情自动化，并更进一步。凭借着在多个领域取得显著突破，为科学开破新路径，加快科学创新步伐，人工智能正在引领一场新的科学革命。众行远，作为科学发现的合作伙伴，人工智能和科学家可以一起携手，探索无尽的科学前沿。</p>
            <p class="desc" style="text-align: right;font-size: 10px">本文来自： 36氪神译局</p>`
        },
        {
          title: "《柳叶刀》子刊大规模分析：戴上运动设备，5个月轻松减重1公斤",
          source: "1CARE健康   糖云网",
          date: "2022-08-15 11:01",
          contentDesc: `<p class="desc">近年来，生活活动智能感知设备的活动追踪设备成为智能产品市场的宠儿，根据国际数据公司（IDC）公布的数据信息，2021年我国智能手表和智能手环出货量占据了智能可穿戴设备的半壁江山，其中手环市场出货量1910万台，手表市场出货量更是达到了3956万台。</p>
            <p class="desc">智能可穿戴设备如何在日常生活活动追踪中究竟有什么魔力，能够吸引一批又一批的人群来使用呢？
近日，发表在 The Lancet Digital Health（柳叶刀数字健康子刊） 的一项系统性回顾和荟萃分析研究，试图了解智能可穿戴设备在日常生活活动追踪设备对临床患者和全人群改善身体活动和心理生理方面的作用，这或许可以为解答上面的问题，提供一些思路。</p>
            <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report9_1.jpg" alt="">
            <p class="desc" style="text-align: center;font-style: italic;">图片来源：柳叶刀-数字健康子刊</p>
            <p class="desc">研究人员选取了7大数据库（Embase、MEDLINE、Scopus、SPORT Discus、Ovid Emcare、the Cochrane Library、Web of Science）中智能可穿戴设备（例如运动记录器、智能手环等）与身体活动、生理或心理相关的综述，最终纳入了截至2021年4月8日发表的39项综述，涉及到近16.4万人。研究结果显示，使用了智能可穿戴生活活动追踪设备后，每天可以多走路近40分钟，大约1800步，相当于每天进行中高强度身体活动6分钟，可在5个月内减轻体重1公斤。目前，涉及可穿戴活动追踪设备与生理和心理关系的研究活动并不多见，发现智能可穿戴生活活动追踪设备不影响生活质量，对于血压、胆固醇、糖化血红蛋白等生理指标，以及疼痛感等心理指标则均无明显影响。本文作者Ty Ferguson博士表示，虽然大家对智能可穿戴设备的接受度很高，但对于使用这些设备是否真的有效普遍存疑，从这次的数据结果来看，整体影响还是比较积极有效的。这或许是因为智能可穿戴设备已经成为人们生活的一部分，可以起到鼓励人们定期锻炼的作用。虽然，将近半年的时间瘦1公斤，这个减重效果看起来似乎并没有那么吸引人。对此，来自南澳大利亚大学的 Carol Maher 教授解释说明道：“本次分析所纳入的研究对像，并不是以减肥为主要目的，因此，研究参与者的体重没有非常明显的下降是合理的。正常来说，普通人每年会增重0.5公斤左右，如果可以在近半年的时间内瘦掉1公斤，那效果还是很不错的”。</p>
           `
        },
      ],
      articleActived: {},
    };
  },
  methods: {
    handleValueToStr(value) {
      switch (value) {
        case "a0b923820dcc509a":
          return 1;
        case "9d4c2f636f067f89":
          return 2;
        case "4b5ce2fe28308fd9":
          return 3;
        case "a2f3e71d9181a67b":
          return 4;
        case "bbce2345d7772b06":
          return 5;
        case "5a880faf6fb5e608":
          return 6;
        case "ceea167a5a36dedd":
          return 7;
        case "fb98ab9159f51fd0":
          return 8;
        case "2e2d7fbdea1afc51":
          return 9;
      }
    },
  },
  mounted() {
    let num = this.handleValueToStr(this.$route.query.id) - 1;
    this.articleActived = this.articleList[num];
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #eef1f5;
}
.horizontal-line {
  width: 73px;
  height: 5px;
  background: #90c446;
}
.title-box {
  .title {
    font-family: "UI-Bold";
    font-size: 52px;
    color: #1f3876;
  }
  .subtitle {
    font-family: "CN-Light";
    font-size: 31px;
    margin-top: 5px;
    color: #90c446;
  }
  .info {
    display: flex;
    margin-top: 29px;
    &-left {
      margin-right: 30px;
      img {
        width: 23px;
        height: 24px;
      }
    }
    &-right {
      flex: 1;
      font-family: "CN-Light";
      font-size: 21px;
      color: #727171;
    }
  }
}
.banner-box {
  position: relative;
  &-img {
    width: 100%;
  }
  &-text-box {
    position: absolute;
    // width: 1200px;
    box-sizing: border-box;
    // height: 300px;
    left: 360px;
    // right: 0;
    top: 128px;
    // margin: 0 auto;
    color: white;
    &-title {
      font-size: 64px;
      font-family: "UI-Bold";
    }
    &-tips {
      font-family: "CN-Light";
      font-size: 31px;
      margin-top: 17px;
    }
    &-info {
      font-family: "CN-Light";
      line-height: 40px;
      color: #213763;
      width: 427px;
    }
    &-btn {
      width: 120px;
      height: 30px;
      margin-top: 60px;
      cursor: pointer;
    }
    &-btn:hover {
      transform: scale(1.02);
      transition: all 0.5s;
    }
  }
}
.section1 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  &-header-box {
    margin-left: 30px;
    &-title {
      font-size: 52px;
      font-family: "UI-Regular";
      color: #1f3876;
      font-weight: bold;
    }
    &-tips {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &-text {
        font-size: 31px;
        font-family: "CN-Light";
        color: #90c446;
        margin-top: 5px;
      }
      &-morecont {
        a {
          font-size: 21px;
          color: #727171;
          text-decoration: none;
          padding: 0 18px;
          border-left: 3px solid #90c446;
          font-weight: bold;
        }
        img {
          width: 102px;
          height: 20px;
        }
      }
    }
  }
}
.carousel-box {
  width: 100%;
  .el-carousel {
    position: relative;
  }
  .carousel-item {
    .carousel-img {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      z-index: -1;
    }
    .carousel-item-text-box {
      padding: 286px 0 0 39px;
      &-line {
        width: 35px;
        height: 5px;
        background: #fff;
        margin-bottom: 5px;
      }
      &-title {
        font-family: "CN-Bold";
        font-size: 25px;
        color: #1f3876;
        line-height: 32px;
        width: 340px;
      }
      &-info {
        font-size: 15px;
        line-height: 19px;
        color: #1f3876;
        text-indent: 30px;
        margin-top: 20px;
      }
    }
  }
  /deep/ .el-carousel__indicators {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
  }
  /deep/ .el-carousel__indicator--horizontal .el-carousel__button {
    width: 18px;
    height: 7px;
    border-radius: 20px;
    background: white;
    opacity: 1;
    margin-right: 10px;
  }
  /deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button {
    background: #1f3876;
    width: 40px;
  }
}
.articleCont {
  padding: 60px 0 100px;
  .title {
    text-align: center;
    padding: 20px 0 0;
    font-family: "CN-Light";
    color: #727171;
    font-size: 26px;
  }
  .source {
    font-family: "CN-Light";
    color: #727171;
    font-size: 21px;
    line-height: 32px;
    padding: 20px 0;
    text-align: center;
    border-bottom: 1px solid #727171;
  }
  .content {
    margin-top: 40px;
    /deep/.desc {
      font-family: "CN-Light";
      color: #727171;
      font-size: 21px;
      line-height: 32px;
      text-indent: 44px;
      text-align: justify;
      padding-bottom: 30px;
    }
    /deep/img {
      width: 100%;
      margin: 20px 0;
    }
  }
}
</style>
